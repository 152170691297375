import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/DiscoverLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Overview`}</h1>
    <p>{`Conceptually, a CoreMedia system can be divided into the Content
Management Environment where editors create and manage the content and the
Content Delivery Environment where the content is delivered
to the customers. Some components are used in both environments, mostly to give you a realistic
preview of your websites. The following image provides an
overview of a CoreMedia system with most of the components installed:`}</p>
    <p><img parentName="p" {...{
        "src": "/de3b9d46b145713155ee99ce93883b7a/CMS_Architecture_Overview_Dia.svg",
        "alt": null
      }}></img>{`
`}<em parentName="p">{`CoreMedia System Overview`}</em></p>
    <h2>{`General Workflow`}</h2>
    <p>{`On the production side of the CoreMedia system, content is created and edited with CoreMedia Studio, with custom clients
or imported by the Content Hub or importers. Once editing or import of contents is completed, they are approved and published via the
CoreMedia Workflow Server. During the publication process, the content is put online onto the Master Live Server. If available,
Replication Live Servers get noticed and reproduce the changes. Then the content is put online by the Replication Live Server.
User generated content is produced via Elastic Social and is stored in MongoDB. Editors can use the Studio plugin to moderate this content.`}</p>
    <h2>{`Content Delivery`}</h2>
    <p>{`The CoreMedia CAE in combination with Adaptive Personalization and Elastic Social creates dynamic HTML pages or any other
format (XML, PDF, etc.) from the internal and external content and CoreMedia templates.`}</p>
    <p>{`Headless Server, on the other hand, delivers content from CoreMedia Content Cloud as JSON data via a GraphQL endpoint. This
gives you you full flexibility in choosing your frontend technology. The Headless Server is fully integrated in CoreMedia Studio.
Therefore, you can preview and edit all changes in Studio.`}</p>
    <h2>{`Content Personalization`}</h2>
    <p>{`CoreMedia contains the Personalization Hub which offers client-side and server-side personalization.`}</p>
    <p>{`Client-side personalization allows to connect the CoreMedia system with third-party personalization systems like Kibo on
client-side.`}</p>
    <p>{`Server-side personalization enables enterprises to deliver the most appropriate content to users depending on the ‘context’ –
the interaction between the user, the device, the environment and the content itself.
Server-side personalization is a powerful personalization tool. Through a series of steps it can identify relevant content
for individuals. It can draw on a user’s profile, commerce segment, preferences and even social network behavior. Use`}</p>
    <p>{`The GUI of both variants is integrated into CoreMedia Studio for easy creation of personalized content.`}</p>
    <h2>{`Content Storage`}</h2>
    <p>{`The Content Servers store their content in relational database systems. Large  blobs can also be stored in the file system.
User generated content is stored in a MongoDB database.`}</p>
    <h2>{`Content Search`}</h2>
    <p>{`A CoreMedia CMS system comes with Apache Solr as the default search engine, which can be used from the editors on content
management site and from the applications on content delivery site. The editor, for example, can perform a fast full text
search in the complete repository. The pluggable search engine API allows you to use other search engines than Apache Solr
for the website search.`}</p>
    <h2>{`Commerce Hub`}</h2>
    <p>{`For eCommerce use cases, the CoreMedia system can be connected with eCommerce systems through the Commerce Hub. Content
from the commerce server is not copied into the CoreMedia system. Instead, references to the content are hold
and are resolved when content is delivered.`}</p>
    <h2>{`User Management`}</h2>
    <p>{`CoreMedia Content Cloud has an integrated user management, but also supports an LDAP server for user management.`}</p>
    <h1>{`Communication of the Components`}</h1>
    <p>{`Communication between the individual components on both the production side and the Live Server is performed via CORBA
and HTTP. MongoDB uses the Mongo Wire Protocol. The Production and Live Systems can be secured with a Firewall if the
servers are located on different computers. The servers contact the databases over a JDBC interface,`}</p>
    <p>{`CoreMedia Content Cloud and the commerce systems communicate over REST interfaces. The concrete communication differs
slightly based on the selected deployment scenario which are the content-led scenario for HCL Commerce and the commerce-led scenario.`}</p>
    <p>{`The following table lists all components, describes the purpose of a component  and shows with which component
a specific component communicates.`}</p>
    <table>
      <tr>
    <th>Application</th>
    <th>Purpose</th>
    <th>Multiple Instances</th>
    <th>Communicates with</th>
      </tr>
      <tr>
        <td>Content Management Server</td>
        <td>Manages the content in the Management Environment and publishes content to the Master Live Server.</td>
        <td>No</td>
        <td>
          <ul>
            <li>All clients</li>
            <li>Publishes content to the Master Live Server</li>
            <li>External relational database</li>
            <li>Search Engine</li>
            <li>Studio Server</li>
            <li>LDAP Server</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Master Live Server</td>
        <td>Manages the content in the Delivery Environment</td>
        <td>Multiple instances when Multi-Master is used </td>
        <td>
          <ul>
            <li>All clients</li>
            <li>External relational database</li>
            <li>LDAP Server</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Replication Live Server</td>
        <td>Serves content to the CAEs and Headless Server</td>
        <td>Multiple instances can be attached to one Master Live Server</td>
        <td>
          <ul>
            <li>Content Application Engine </li>
            <li>Headless Server</li>
            <li>External relational database</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Workflow Server</td>
        <td>Executes workflows</td>
        <td>No</td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>External relational database</li>
            <li>MongoDB</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Site Manager</td>
        <td>Management tool for workflows and users</td>
        <td>Yes</td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>Workflow Server</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Studio Server</td>
        <td>Content editing and management. Hosts management extensions for Elastic Social and Adaptive Personalization. </td>
        <td>One web application</td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>Workflow Server</li>
            <li>External relational database</li>
            <li>Search Engine</li>
            <li>MongoDB</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Search Engine</td>
        <td>Indexes content and provides searches functionality. </td>
        <td>Yes</td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>External relational database</li>
            <li>Search Engine</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>CAE Feeder</td>
        <td>Feeds content beans into the Search Engine</td>
        <td>Multiple instances possible, for example when reindexing. </td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>External relational database</li>
            <li>Search Engine</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Content Feeder</td>
        <td>Serves content to the Search Engine</td>
        <td>Multiple instances possible, for example when reindexing. </td>
        <td>
          <ul>
            <li>Content Management Server</li>
            <li>Search Engine</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Content Application Engine</td>
        <td>Serves sites to the customer. Hosts Elastic Social and Adaptive Personalization extension.</td>
        <td>Multiple instances can be attached to one Master Live Server or Replication Live Server</td>
        <td>
          <ul>
            <li>Content Server</li>
            <li>Search Engine</li>
            <li>MongoDB database for Elastic Social</li>
            <li>Custom external systems</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Headless Server</td>
        <td>Serves content to the customer.</td>
        <td>Multiple instances can be attached to one Master Live Server or Replication Live Server</td>
        <td>
          <ul>
            <li>Replication Live Server</li>
            <li>Search Engine</li>
            <li>Commerce Adapter</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Commerce Adapter</td>
        <td>Connects external commerce systems with the CoreMedia system.</td>
        <td>Multiple instances can connect multiple commerce systems.</td>
        <td>
          <ul>
            <li>Studio</li>
            <li>Headless Server</li>
            <li>Content Application Engine</li>
          </ul>
        </td>
      </tr>
      <tr>
        <td>Importer</td>
        <td>Imports content into the Content Management Server. </td>
        <td>Yes</td>
        <td>
          <ul>
            <li>Content Management Server</li>
          </ul>
        </td>
      </tr>
    </table>
    <h2>{`Further Reading`}</h2>
    <ul>
      <li parentName="ul">{`Get a more detailed overview in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/coremedia-en/content/OverviewOfSystem.html"
        }}>{`Blueprint Developer Manual`}</a></li>
      <li parentName="ul">{`Learn how to monitor services in the  `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/operation-basics-en/content/Introduction.html"
        }}>{`Operations Basics Manual`}</a></li>
      <li parentName="ul">{`Learn how to start a CoreMedia system in Docker containers in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/how-to-guides/quick-start/"
        }}>{`Quickstarts`}</a></li>
      <li parentName="ul">{`Learn more about the Content Servers and their database setup in the `}<a parentName="li" {...{
          "href": "https://documentation.coremedia.com/cmcc-11/current/webhelp/contentserver-en/content/index.html"
        }}>{`Content Server Manual`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      